import { h } from 'preact';

const IS_IE = isIE();

//fallback to jpg in case of ie, uses svg otherwhise
export default function illustration (props) {
  const image = IS_IE ? props.src + '.jpg' : props.src + '.svg';

  return <img alt={ props.alt } src={ image } />;
};

function isIE() {
  if (typeof window === 'undefined') return false;

  let ua = window.navigator.userAgent,
  msie = ua.indexOf("MSIE ");

  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

}
