import { h } from 'preact';
import style from './style.scss';

const Menumobiletoggle = (props) => (
	<div class={ style.hamburgher + ' menuItemCheck' } onClick={ props.cb }>
		<span class={style.bars}></span>
		<span class={style.bars}></span>
		<span class={style.bars}></span>
	</div>
);

export default Menumobiletoggle;