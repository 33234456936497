import { h } from 'preact';
import style from './style.scss';
import { BASE_URL } from "../../config";
import {Link} from "preact-router/match";

const Footer = () => (
	<footer class={style.foot}>
		<div className={style.mainWrp}>
			<div>
				<nav>
					<ul>
						<li>
							<Link href={BASE_URL + 'privacy'}>Data Protection Policy</Link>
						</li>
						<li>
							<Link href={BASE_URL + 'cookies'}>Cookies Policy</Link>
						</li>
						<li>
							<Link href={BASE_URL + 'terms'}>User Agreement</Link>
						</li>
						<li>
							<Link href={BASE_URL + 'website_disclaimer'}>Disclaimer</Link>
						</li>
						<li>
							<Link href={BASE_URL + 'contacts'}>Contacts</Link>
						</li>
					</ul>
				</nav>
				<p>© Querlo LLC 2023 all rights reserved</p>
			</div>

			<ul class={ style.socials }>
				<li>
					<a href="https://www.linkedin.com/company/Querlo/" target="_blank"><i className="fab fa-linkedin"></i></a>
				</li>
				<li>
					<a href="https://discord.gg/9BfZXVBB" target="_blank"><i className="fab fa-discord"></i></a>
				</li>
				<li>
					<a href="https://www.youtube.com/channel/UCpr2xRv5W2AAmNTpteaPKGg/videos" target="_blank"><i className="fab fa-youtube"></i></a>
				</li>
				<li>
					<a href="https://www.facebook.com/QuerloAI" target="_blank"><i className="fab fa-facebook-square"></i></a>
				</li>
			</ul>
		</div>
	</footer>
);

export default Footer;
