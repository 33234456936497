import {Component, h} from 'preact';
import { Link } from 'preact-router/match';
import Illustration from "../illustration";
import Mobilemenutoggle from "../menumobiletoggle";
import style from './style.scss';
import { STATIC_BASE_URL, BASE_URL, SITE_URL } from "../../config";

class Header extends Component {

	state = {showFirstMenu: false, showSecondMenu: false};

	toggleFirstMenu = () => {
		this.setState({showFirstMenu: !this.state.showFirstMenu, showSecondMenu: false, showThirdMenu: false});
	};

	toggleSecondMenu = () => {
		this.setState({showSecondMenu: !this.state.showSecondMenu, showFirstMenu: false, showThirdMenu: false});
	};

	toggleThirdMenu = () => {
		this.setState({showThirdMenu: !this.state.showThirdMenu, showFirstMenu: false, showSecondMenu: false});
	};

	render(props, state) {

		const userMenu = props.u ? (<ul className={[style.userMenu, style.loggedin].join(' ')}>
			<li className={[style.accessButt, style.register].join(' ')}>
				<a href={SITE_URL + 'studio'} native>Chatbot Studio</a>
			</li>
			<li className={[style.accessButt, style.login].join(' ')}>
				<a href={SITE_URL + 'logout'} native><i className="fa fa-sign-out-alt"></i></a>
			</li>
		</ul>) : (<ul class={style.userMenu}>
			<li className={[style.accessButt, style.register].join(' ')}>
					<Link activeClassName={style.sel} href={BASE_URL + 'register'}>Try for free</Link>
				</li>
				<li className={[style.accessButt, style.login].join(' ')}>
					<Link
						activeClassName = {style.sel}
						href={BASE_URL +'login'} > Sign in
					</Link>
			</li>
		</ul>);

		const mainMenu = <>
				<ul class='mainMenu' style={ !props.isStudio ? {display: 'flex'} : {display: 'none'} }>
					<li><Link activeClassName={ style.sel } href={ BASE_URL + 'about'}>About</Link></li>
					<li class={ this.state.showFirstMenu ? style.hovered : "" }
							onMouseOver={ this.toggleFirstMenu }
							onMouseOut={ this.toggleFirstMenu }>
						<a>Products <i class="fa fa-angle-down"></i></a>
						<ul>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'digitaltwin'}>Digital Twin</Link></li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/chatbot-studio'}>Chatbot Studio</Link></li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/metaverse'}>Metaverse</Link></li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/hr-assistant'}>HR Digital Assistant</Link>
							</li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'michelangelo'}>Michelangelo AI</Link></li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/enterprise-solution'}>Enterprise
								Solution</Link></li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/forbes-lead-insights'}>Forbes Lead
								Insights</Link></li>
							<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/online-lead-gen'}>Lead Gen</Link></li>
							{/*<li><Link activeClassName={ style.sel } href={BASE_URL + 'products/ai-social-lab'}>AI Social Lab</Link></li>*/}
						</ul>
					</li>
					<li class={this.state.showSecondMenu ? style.hovered : ''} onMouseOver={this.toggleSecondMenu} onMouseOut={this.toggleSecondMenu}>
						<a>Work with us <i class="fa fa-angle-down"></i></a>
						<ul>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'partners/list'}>Partners</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'experts'}>Experts</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'careers'}>Careers</Link>
							</li>
						</ul>
					</li>
					<li><a href="https://blog.Querlo.com" target="_blank">Blog</a></li>
					<li><Link activeClassName={ style.sel } href={BASE_URL + 'contacts'}>Contacts</Link></li>
				</ul>
				<ul class="chatbotStudioMenu" style={ props.isStudio ? {display: 'flex'} : {display: 'none'} }>
					<li class={ this.state.showThirdMenu ? style.hovered : "" }
							onMouseOver={ this.toggleThirdMenu }
							onMouseOut={ this.toggleThirdMenu }>
						<a>Features</a>
						<ul>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/tree-builder'}>
									<i class="fa fa-tree" /> Tree builder
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/natural-language-understanding-ai-nlu'}>
									<i class="fa fa-robot" /> Artificial Intelligence
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/voice'}>
									<i class="fa fa-volume-up" /> Voice
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/live-chat-human-takeover'}>
									<i class="fa fa-user-crown" /> Live Chat
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/apis'}>
									<i class="fa fa-tape" /> APIs
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/multimedia'}>
									<i class="fa fa-image" /> Multimedia
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/multichannel'}>
									<i class="fa fa-mobile-alt" /> Multichannel
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/customization'}>
									<i class="fa fa-swatchbook" /> Customization
								</Link>
							</li>
							<li>
								<Link activeClassName={ style.sel } href={BASE_URL + 'chatbot-studio/analytics'}>
									<i class="fa fa-chart-bar" /> Analytics
								</Link>
							</li>
						</ul>
					</li>
					<li>
						<Link activeClassName={ style.sel } href={ BASE_URL + 'chatbot-studio/pricing'}>
							Pricing
						</Link>
					</li>
					<li>
						<a href="https://blog.Querlo.com/category/documentation" target="_blank">
							Documentation
						</a>
					</li>
					<li>
						<Link activeClassName={ style.sel } href={ BASE_URL}>
							Company
						</Link>
					</li>
				</ul>
			</>;

		return (<header class={ style.headMenu }>
			<div class={ style.mainWrp }>
				<div class={ `logo ${style.logo}` }>
					<a href={ props.isStudio ? BASE_URL + 'chatbot-studio' : BASE_URL } class={ props.isStudio ? style.logoChatbotStudio : '' }>
						<Illustration src={STATIC_BASE_URL + 'logo-querlo'} alt="Querlo"/>
						{ props.isStudio ? <div>Chatbot Studio</div> : <div>Customized Artificial Intelligence Solutions</div> }
					</a>
				</div>
				<Mobilemenutoggle cb={ props.cb }/>
				<nav id="mainMenu"
						 class={ style.navStyle + ' main-nav menuItemCheck' }
						 role="navigation">

					{ mainMenu }
					{ userMenu }

				</nav>
			</div>
		</header>);
	}

}

export default Header;
