import { h, Component } from 'preact';
import {STATIC_BASE_URL, BASE_URL} from "../../config";
import {Link} from "preact-router/match";
import style from './style.scss';
import DeviceFrame from "../../components/device-frame";

export default class Chatbotstudio extends Component {

	constructor() {
		super();
		this.state = {active: false};
	}

	showYT (e) {
		e.preventDefault();
		this.props.ytCB('chatbot-studio-demo.mp4');
	}

	render (props, state) {

		const callToAction = <p class="center calltoaction"><a href="#" class="butt huge" onClick={ this.showYT.bind(this) }>Guarda una demo</a> <Link href={BASE_URL + 'register'} class="butt huge">Provalo Gratis</Link></p>;

		return <div id="chatbotstudio" class="product">
			<div class="mainWrp">
				<section class="body center">
					<h1>Chatbot Studio</h1>
					<h2 style={{margin: '2rem 0 4rem 0'}}>Crea il tuo chatbot in pochi minuti.<br />Senza nessuna programmazione!</h2>
					{ callToAction }
				</section>
			</div>
			<div style={{padding: '2rem'}}>
				<ul class={style.featuresMenu}>
					<li><a native href="/products/chatbot-studio#ai"><i class="fa fa-robot" /> IA</a></li>
					<li><a native href="/products/chatbot-studio#voice"><i class="fa fa-volume-up" /> Voice</a></li>
					<li><a native href="/products/chatbot-studio#human-takeover"><i class="fa fa-user-crown" /> Live Chat</a></li>
					<li><a native href="/products/chatbot-studio#multichannel"><i class="fa fa-mobile-alt" /> Multicanale</a></li>
				</ul>
			</div>
			<section class="full grey">
      <p>
					Chatbot Studio di Querlo è la piattaforma ideale dove creare il tuo chabot!<br /> Partendo da un semplice flusso deterministico di domande e risposte, puoi implementare gradualmente  funzionalità più complesse basate sull'intelligenza artificiale e integrate con qualsiasi API esterna.</p>
				<p>
					I nostri Chatbot sono altamente personalizzabili fin nei più piccoli dettagli.<br /> Puoi creare un chatbot dedicato alla tua attività aggiungendo il tuo logo, personalizzando i colori e l'aspetto fino ad ottenere una naturale integrazione con il tuo sito web.</p>
			</section>
			<blockquote>
				"Elementi dell'IA come l'apprendimento automatico, l'elaborazione del linguaggio naturale e le reti neurali sono pronti ad alimentare la prossima ondata di rivoluzione digitale"<span>Verizon</span>
			</blockquote>

			<section class="features center cols full" id="ai">
				<div class="mainWrp">
					<h2>Benefici dell'Intelligenza Artificiale</h2>
					<div class="col">
						<div class="col first">
							<h3><i class="fa fa-swatchbook" /> Crea ed istruisci la tua IA in pochi minuti</h3>
							<p>
								Partendo dai nostri modelli puoi realizzare un chatbot semplice e intelligente in pochi minuti. Non c'è più motivo di sentirsi sopraffatti dalla parola "IA": con Querlo Chatbot Studio puoi creare ed istruire la tua IA in tutta semplicità .</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-undo-alt" /> Pieno Controllo</h3>
							<p>
								Con Querlo Chatbot Studio hai sempre il pieno controllo dell'IA delle tue chat. Puoi monitorarne le performance attraverso dettagliati report analitici ed effettuare modifiche mirate, se e quando necessario.</p>
						</div>
					</div>
					<div class="col">
						<div class="col">
							<h3><i class="fa fa-grin-hearts" /> Sentiment Analysis</h3>
							<p>
								I nostri chatbot sono in grado di analizzare il tono della risposta dell'utente e determinare se la sua esperienza è positiva o negativa, permettendoti di dare la piega giusta alla conversazione a seconda del caso.</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-brain" /> Intelligenza conversazionale</h3>
							<p>
								Grazie all'integrazione delle varie funzionalità di Intelligenza Conversazionale, il tuo chabot sarà in grado di offrire un'esperienza interattiva di altissimo livello.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section class="features full center cols grey">
				<h2>Caratteristiche</h2>
				<div class="mainWrp">
					<div class="col">
						<div class="col first">
							<h3><i class="fa fa-mouse-pointer" /> Chatbot Editor</h3>
							<p>
								Crea il tuo chatbot in semplici clic, con l'editor grafico di Querlo Chatbot Studio. Il flusso della conversazione è chiaro e immediato grazie al nostro formato ad albero decisionale.
							</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-laptop-code" /> Pubblicazione istantanea del Chabot</h3>
							<p>
								Con un semplice copia e incolla del codice di embed nella tua pagina web, il chatbot verrà visualizzato sul tuo sito web in un iframe o in una finestra pop-up.</p>
						</div>
					</div>
					<div class="col">
						<div class="col">
							<h3><i class="fa fa-chart-bar" /> Analytics</h3>
							<p>
								Il nostro dettagliato Dashboard Analytics ti fornirà informazioni utili a conoscere il tuo pubblico e la performance aziendali dei tuoi canali gestiti dal chabot.</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-swatchbook" /> Altamente personalizzabile</h3>
							<p>
								Ogni elemento della chat è personalizzabile: aggiungi i tuoi file multimediali, carica avatar, seleziona sfondo e lingua, colorie e velocità di digitazione. Puoi persino inserire CSS personalizzati per i casi più avanzati.
							</p>
						</div>
					</div>
				</div>
				<div class="mainWrp">
					<div class="col allalone">
						<div class="col">
							<h3><i class="fa fa-tape" /> Integrazione API</h3>
							<p>
								Facilmente integrabile con qualsiasi API esterna, Querlo offre anche una flessibile interfaccia API interna per sviluppare integrazioni avanzate con la tua piattaforma web.
							</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-language" /> NLU</h3>
							<p>
								Tramite le funzioni di Riconoscimento Naturale del Linguaggio (NLU) puoi rendere il tuo chatbot ancora più interattivo e intelligente! Puoi istruire in autonomia il tuo modello NLU o rivolgerti ai nostri esperti che sapranno fornirti tutta l'assistenza necessaria.</p>
						</div>
					</div>
				</div>
			</section>
			<section class="features full center cols" id="human-takeover">
				<h2>Live Chat</h2>
				<div class="mainWrp">
					<div class="col">
						<div class="col first">
							<h3><i class="fa fa-user-crown"></i> Human takeover</h3>
							<p>
								Consenti agli utenti di richiedere assistenza a un essere umano.<br/>
								Decidi quali domande gestirà direttamente il tuo team.
							</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-laptop-code"></i> Notifications</h3>
							<p>
								Email, Notifiche native, Discord, ricevi notifiche quando qualcuno vuole parlare con te!
							</p>
						</div>
					</div>
					<div class="col">
						<div class="col">
							<h3><i class="fa fa-robot"></i> E torniamo al bot!</h3>
							<p>
								Riporta la conversazione al chatbot, una volta che hai finito.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section class="features center cols full grey" id="voice">
				<div class="mainWrp">
					<h2>Voice</h2>
					<div class="col">
						<div class="col first">
							<h3><i class="fa fa-volume-up" /> Fai parlare il tuo assistente virtuale!</h3>
							<p>
								I nostri chatbots hanno la possibilità di utilizzare la tecnologia <b>text-to-speech</b> con un qualità audio altissima.
							</p>
						</div>
						<div class="col">
							<h3><i class="fa fa-globe-europe" /> Tutte le lingue del mondo</h3>
							<p>
								Scegli la tua lingua preferita. Per ogni lingua supportata sono disponibili numerosi modelli vocali, sia maschili che femminili.
							</p>
						</div>
					</div>
					<div class="col">
						<div class="col first">
							<h3><i class="fa fa-microphone-alt" /> Comandi vocali</h3>
							<p>
								I nostri Chatbots sono in grado di ascoltare comandi vocali e di interpretarne il significato.
							</p>
						</div>
					</div>
				</div>
			</section>
			<div class="mainWrp" id="multichannel">
				<section>
					<h2>Multi Canale</h2>
					<ul class={style.multichannel}>
						<li>
							<i class="fab fa-html5" />
							<p>Websites</p>
						</li><li>
							<i class="fab fa-whatsapp" />
						  <p>Whatsapp</p>
						</li>
						<li>
						  <i class="fab fa-facebook-messenger" />
						   <p>FB Messenger</p>
						</li>
					</ul>
				</section>
			</div>
			<section class="full grey">
				{ callToAction }
			</section>
		</div>;
	}
}
