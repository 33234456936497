import { h, Component } from 'preact';
import style from './style.scss';
import { BASE_URL } from '../../config';

/*if (typeof window !== 'undefined') {
	const tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";
	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}*/

// let player = null;

export default class YoutubePopup extends Component {

	/*componentDidMount() {
		if (typeof window !== 'undefined') {
			player = new YT.Player('playerYo', {
				height: '360',
				width: '640',
				videoId: this.props.data
			});
		}
	}*/

	close = (e) => {
		if (e.target.className.indexOf('close') !== -1) {
			// player.stopVideo();
			this.props.cb();
		}
	};

	render (props, state) {

		/*if (typeof window === 'undefined') return '';
		return <div class={style.youtubePopup + ' close animated fadeIn'} onClick={ this.close }>
			<button class={style.closePopup + ' close animated fadeInDown'}><i class="close fa fa-times">&nbsp;</i></button>
			<div id="playerYo" class="animated fadeInUp">&nbsp;</div>
		</div>;*/

		return (<div class={style.youtubePopup + ' close animated fadeIn'} onClick={this.close}>
			<button class={style.closePopup + ' close animated fadeInDown'}><i class="close fa fa-times">&nbsp;</i></button>
			<video controls autoPlay playsInline>
				<source src={BASE_URL + 'assets/public/' + props.data} type="video/mp4" />
			</video>
		</div>);
	}

}
