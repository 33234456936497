import style from './style.scss';
import {useEffect} from "preact/compat";

function findGetParameter(parameterName) {
	var result = null,
	tmp = [];
	location.search
	.substr(1)
	.split("&")
	.forEach(function (item) {
		tmp = item.split("=");
		if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	});
	return result;
}

const Chatwrp = ({chatID, callback}) => {

	if (!chatID) chatID = (typeof window !== 'undefined' && findGetParameter('id')) ? findGetParameter('id') : 13818;

	useEffect(() => {
		// set listeners to events only if window is set (prerender thing) and if callbacks are requested thru params
		if (typeof window !== 'undefined' && callback) {
			window.addEventListener('message', handleMessage, false);
			return () => {
				window.removeEventListener('message', handleMessage, false);
			};
		}
	}, []);

	const handleMessage = (e) => {
		if (e.origin !== 'https://chat.querlo.com' ||
			e.data.querloMessageType !== 'clientEvent') {
			return;
		} else {
			callback(e.data.command, e);
		}
	}

	return <div class={style.chatwrp}>
		<iframe src={ "https://chat.querlo.com/" + chatID } allow="microphone;camera;midi;encrypted-media;" frameBorder="0"></iframe>
	</div>
}

export default Chatwrp;
