import {Component, h} from 'preact';
import style from './style.scss';
import {BASE_URL} from "../../config";
export default class CookiePopup extends Component {

	accept = e => {
		e.preventDefault();
		this.props.cb(true);
	};

	close = e => {
		e.preventDefault();
		this.props.cb(false);
	};

	render (props, state) {
		return <div class={style.cookiePopup}>
			<div class="animated fadeInUp">
				{/*<button onClick={ this.close } className={style.closePopup + ' close butt secondary'}><i className="close fal fa-times">&nbsp;</i></button>*/}
				<h3>We value your privacy</h3>
				<p>
					Querlo makes use of technical necessary cookies on this website for functionality purposes.
				</p>
				<p>
					For better user experience, you may consent to the use of optional cookies. These will remember your preferences upon revisiting the website and analyze the traffic to make improvements.
				</p>
				<p class={style.choice}>
					<button onClick={ this.accept }><i className={'fal fa-cookie-bite'}></i> Accept</button>
					{/*<button onClick={ this.close } class="butt secondary">Decline</button>*/}
				</p>
				<p>
					Please read our <a href={BASE_URL + 'cookies'} target="_blank">Cookie Policy page</a> for more information.
				</p>
			</div>
		</div>;
	}

};
