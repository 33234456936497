import { h, Component } from 'preact';
import style from './style.scss';

export default class ChatPopup extends Component {

	close = (e) => {
		if (e.target.className.indexOf('close') != -1) {
			this.props.cb();
		}
	};

	render (props, state) {
		if (typeof window === 'undefined') return '';
		return <div class={style.chatPopup + ' close animated fadeIn'} onClick={ this.close }>
			<button class={style.closePopup + ' close animated fadeInDown'}><i class="close fa fa-times">&nbsp;</i></button>
			<iframe src={'https://www.querlo.com/chat/' + props.data} className="animated fadeInUp">&nbsp;</iframe>
		</div>;
	}

};
