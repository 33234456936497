import {createRef, h} from 'preact';
import style from './style.scss';
import {BASE_URL, STATIC_BASE_URL} from "../../config";

const devices = {
	macbookair: 'frame_macbookair.png',
	ipad: 'frame_ipad.png',
	iphone: 'frame_iphone.png',
}

export default ({deviceType, mediaURL, title, mediaType}) => {

	const ref = createRef();
	const type = mediaType || 'video';

	const expand = () => {
		ref.current.requestFullscreen();
	}

	return <div class={'frame ' + deviceType}>
		<img class="frameImg" src={STATIC_BASE_URL + 'public/' + devices[deviceType]} alt={title} />
		<div class="inframeWrp">
			{ type === 'video' ? <video
						 autoPlay
						 loop
						 muted
						 ref={ref}
						 className="inframe"
						 src={STATIC_BASE_URL + mediaURL}/> : '' }
			{ type === 'iframe' ? <iframe src={mediaURL} /> : '' }
			{ type === 'video' ? <button type="button"
							class={style.expandButt}
							onClick={expand}>
				<i class="fa fa-expand"></i>
				<span>Fullscreen</span>
			</button> : '' }
		</div>
	</div>;

}
